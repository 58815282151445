(function ($) {
  'use strict'; // Start of use strict

  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
    if (
      location.pathname.replace(/^\//, '') ==
        this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate(
          {
            scrollTop: target.offset().top - 72,
          },
          1000,
          'easeInOutExpo'
        );
        return false;
      }
    }
  });

  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll-trigger').click(function () {
    $('.navbar-collapse').collapse('hide');
  });

  // Activate scrollspy to add active class to navbar items on scroll
  $('body').scrollspy({
    target: '#mainNav',
    offset: 74,
  });

  // Collapse Navbar
  var navbarCollapse = function () {
    if ($('#mainNav').offset().top > 100) {
      $('#mainNav').addClass('navbar-shrink');
    } else {
      $('#mainNav').removeClass('navbar-shrink');
    }
  };
  // Collapse now if page is not at top
  navbarCollapse();
  // Collapse the navbar when page is scrolled
  $(window).scroll(navbarCollapse);
})(jQuery); // End of use strict

/* eslint-disable no-undef */
// map our commands to the classList methods
const collapseParent = document.querySelector('#portfolio');
const servicesSection = document.querySelector('#services');

// move window to position
const moveWindow = () => {
  collapseParent.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};

const collapse = (selector) => {
  const targets = collapseParent.querySelectorAll('.collapse');

  targets.forEach((target) => {
    if (!target.classList.contains(selector.substring(1))) {
      target.classList.remove('show');
    }
  });
};

// Grab all the trigger elements on the page
const triggers = Array.from(
  document.querySelectorAll('.selectable-category[data-toggle="collapse"]')
);

function removeClasses(e) {
  const target = e.currentTarget;

  triggers.forEach((btn) => {
    if (btn.isSameNode(target)) {
      if (btn.classList.contains('active')) {
        btn.classList.remove('active', 'box-shadow-light');
      } else {
        btn.classList.add('active', 'box-shadow-light');
        moveWindow();
      }
    } else {
      btn.classList.remove('active', 'box-shadow-light');
    }
  });
  const isSomeActive = triggers.some((trigger) =>
    trigger.classList.contains('active')
  );
  // eslint-disable-next-line no-unused-expressions
  isSomeActive
    ? servicesSection.classList.add('show-chevron')
    : servicesSection.classList.remove('show-chevron');
}

// Listen for click events, but only on our triggers
triggers.forEach((el) => {
  el.addEventListener('click', () => {
    const selector = el.getAttribute('data-target');
    collapse(selector);
  });
});

triggers.forEach((el) => {
  el.addEventListener('click', removeClasses);
});

// modal
// eslint-disable-next-line no-unused-vars
const modalContent = {
  confeccionPlanos: {
    title: 'CONFECCIÓN DE PLANOS',
    text: 'Elaboración de planos as built para diferentes tipos de instalaciones eléctricas, incluyendo plantas, unilineales, cuadros de carga, etc. de acuerdo a la normativa chilena.',
  },
  levantamiento: {
    title: 'LEVANTAMIENTO',
    text: 'Levantamiento eléctrico de instalaciones interiores industriales, comerciales y residenciales, con el fin de identificar y registrar, actualizar información en planos o regularizar nuevos circuitos o componentes de una instalación. ',
  },
  tramitacionSec: {
    title: 'TRAMITACIÓN SEC',
    text: 'Contamos con instaladores autorizados Clase A para declaración de instalaciones eléctricas de interior (TE1). Además, gestión y tramitación de aumento de empalme monofásicos y trifásicos. ',
  },
  iluminacion: {
    title: 'ILUMINACION',
    text: 'Desarrollo de proyectos de iluminación, incluyendo criterios de diseño, memorias de cálculo de acuerdo a cada necesidad y planos, también el montaje y ejecución del proyecto en sí.',
  },
  tableros: {
    title: 'INTEGRACIÓN DE TABLEROS ELÉCTRICOS',
    text: 'Diseño e instalación de tablero de potencia y control, de acuerdo a los requerimientos y necesidades del cliente. ',
  },
  mallas: {
    title: 'SISTEMA DE PUESTA A TIERRA',
    text: 'Mediciones, cálculo y diseño para sistemas de puesta a tierra.',
  },
  mantenimientoMotores: {
    title: 'MANTENIMIENTO DE MOTORES ELÉCTRICOS',
    text: 'Realización de mantenimiento preventivo y reparación de motores eléctricos, para el óptimo funcionamiento en sus procesos productivos. Entre los servicios se ofrece, bobinado de motores, cambio de rodamientos, pruebas de aislación, entre otros.',
  },
  mantenimientoTableros: {
    title: 'MANTENIMIENTO DE TABLEROS',
    text: 'Revision y diagnostico general, reapriete de terminales, limpieza con solvente dieléctrico, aspiración de polvo y partículas métalicas, entre otros.',
  },
  mantenimientoMaquinaria: {
    title: 'MANTENIMIENTO DE MÁQUINARIA Y EQUIPOS',
    text: 'Mantenimiento predictivo y correctivo de maquinaria y equipos industriales, para su buen funcionamiento, evitando así paralización de los procesos de producción. Además de alargar la vida útil de la maquinaria e instalaciones. ',
  },
  diseno: {
    title: 'DISEÑO, CREACIÓN Y FABRICACIÓN DE MÁQUINAS',
    text: 'Diseño, desarrollo y fabricación de máquinas, mediante especificaciones y requerimientos del cliente, dando funcionalidad y utilidad más optima según las necesidades de este. ',
  },
};

$('#globalModal').on('show.bs.modal', function (event) {
  const button = $(event.relatedTarget);
  const service = button.data('service');

  const modal = $(this);
  modal.find('#globalModalTitle').text(modalContent[service].title);
  modal.find('#globalModalText').text(modalContent[service].text);
});
